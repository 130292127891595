// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {AutocompleteSearch} from '@wellstone-solutions/web';
import {Models} from '@wellstone-solutions/common';
import type {
  UILocationSuggestionType,
  UILocationType,
} from '@wellstone-solutions/common';

const {Location} = Models;

type PropsType = {
  onSelect: (location: UILocationType | null) => void,
};

export const LocationSearch = ({onSelect}: PropsType): Node => {
  const [location, setLocation] = useState(null);

  const handleSearch = async (
    query,
  ): Promise<Array<UILocationSuggestionType>> => {
    const response = await Location.getLocationSuggestions({query});
    if (response.isSuccess) {
      return response.data.map(Location.toUILocationSuggestion);
    } else {
      return [];
    }
  };

  const handleOptionSelect = (suggestion: ?UILocationSuggestionType) => {
    if (!suggestion) {
      setLocation(null);
      onSelect(null);
      return;
    }

    const {geometry, ...rest} = suggestion;
    const newLocation = Location.LocationFactory({
      ...rest,
      latitude: geometry.location.lat,
      longitude: geometry.location.lng,
    });
    setLocation(newLocation);
    onSelect(newLocation);
  };

  return (
    <AutocompleteSearch
      fullWidth
      autoSelect={true}
      autoHighlight={true}
      label="Location Search"
      // $FlowFixMe - mismatch on how optional properties are handled on location and suggestion types
      value={location}
      optionValueKey="formattedAddress"
      optionLabelKey="formattedAddress"
      onSearch={handleSearch}
      onOptionSelect={handleOptionSelect}
    />
  );
};
