// @flow
import React from 'react';
import type {Node} from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {useStores} from 'hooks/useStores';

import {ResourceForm} from '../ResourceForm';

type PropsType = {
  resource: UIResourceType | null,
  open: boolean,
  onClose: () => void,
  onSuccess: (resource: UIResourceType) => void,
  onDelete: (id: string) => Promise<void>,
};

export const EditResource = ({
  resource,
  open,
  onClose,
  onSuccess,
  onDelete,
}: PropsType): Node => {
  const {resourceV2Store} = useStores();

  const onSubmit = async (values) => {
    const response = await resourceV2Store.updateResource(values);

    if (response.isSuccess && response.data) {
      onSuccess(response.data);
    }

    return response;
  };

  return (
    <Dialog open={open} fullScreen={true} onClose={onClose}>
      <Box p={4}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}>
          <Stack direction="row" alignItems="center">
            <IconButton icon={IconNames.ArrowLeftCircle} onClick={onClose} />
            <Typography variant="h5">Edit Resource</Typography>
          </Stack>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              if (resource) {
                onDelete(resource.id);
              }
            }}>
            Delete
          </Button>
        </Stack>
        <Box>
          <ResourceForm initialValues={resource} onSubmit={onSubmit} />
        </Box>
      </Box>
    </Dialog>
  );
};
