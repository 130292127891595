// @flow
import React from 'react';
import type {Node} from 'react';
import {Routes as SharedRoutes} from 'components/Routes';
import {routes as questionRoutes} from './Questions/routes';
import {routes as answerRoutes} from './Answers/routes';
import {routes as v2Routes} from '../v2/routes';
export const routes = [
  {
    Component: (): Node => <SharedRoutes routes={answerRoutes} />,
    isProtected: true,
    label: 'All Answers',
    path: 'answers/*',
  },
  {
    Component: (): Node => <SharedRoutes routes={questionRoutes} />,
    isProtected: true,
    label: 'All Questions',
    path: 'questions/*',
  },
  {
    Component: (): Node => <SharedRoutes routes={v2Routes} />,
    isProtected: true,
    path: '*',
  },
];
