// @flow
import type {LabelValueType} from 'types';

export const domains: {
  resources: LabelValueType,
  questions: LabelValueType,
  answers: LabelValueType,
  announcements: LabelValueType,
  stories: LabelValueType,
} = {
  resources: {label: 'Resources', value: 'resources'},
  questions: {label: 'Resource Questions', value: 'resources/questions'},
  answers: {label: 'Resource Answers', value: 'resources/answers'},
  announcements: {label: 'Announcements', value: 'announcements'},
  stories: {label: 'Stories', value: 'stories'},
};

export const root = 'content-management';
export const defaultDomain = domains.resources.value;
