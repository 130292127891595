// @flow
import type {RouteType} from 'types';
import {List} from './components';

export const routes: RouteType[] = [
  {
    Component: List,
    isProtected: true,
    index: true,
  },
];
