// @flow
import React, {useState, useRef} from 'react';
import type {Node} from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import {TextField, Icon, IconButton, IconNames} from '@wellstone-solutions/web';

type PropsType = {
  onSearch: (search: string) => void,
};

export const ResourceSearch = ({onSearch}: PropsType): Node => {
  const [value, setValue] = useState('');
  const inputRef = useRef(null);
  return (
    <TextField
      label="Keyword Search"
      variant="outlined"
      fullWidth
      value={value}
      size="normal"
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSearch(value);
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon name={IconNames.Search} />
          </InputAdornment>
        ),
        endAdornment: value.length > 0 && (
          <InputAdornment position="end">
            <IconButton
              aria-label="Clear search"
              icon={IconNames.Xmark}
              onClick={() => {
                const newValue = '';
                setValue(newValue);
                onSearch(newValue);
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              }}
            />
          </InputAdornment>
        ),
      }}
      inputRef={inputRef}
    />
  );
};
