// @flow
import {useMemo} from 'react';
import type {UILocationType} from '@wellstone-solutions/common';

type PropsType = {
  query: string,
  location: UILocationType | null,
};

const DEFAULT_DISTANCE = 25;

export const useActiveFilters = ({query, location}: PropsType) => {
  const activeFilters = useMemo(() => {
    let activeFilters = {};
    if (query) {
      activeFilters.query = query;
    }

    const {latitude, longitude, state, city} = location || {};
    if (latitude && longitude) {
      activeFilters.lat = latitude;
      activeFilters.lon = longitude;

      if (state && !city) {
        activeFilters.state = state;
      } else {
        activeFilters.distance = DEFAULT_DISTANCE;
      }
    }

    return activeFilters;
  }, [query, location]);

  return activeFilters;
};
