// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Box, Stack, Tooltip, Typography} from '@wellstone-solutions/web';

type PropsType = {
  items: Array<string>,
  showCount?: number,
};

export const ListCell = ({items, showCount = 1}: PropsType): Node => {
  const visibleItems = items.slice(0, showCount);
  const hiddenItemCount = items.length - visibleItems.length;
  const showAdditionalItems = hiddenItemCount > 0;

  return (
    <Box sx={{flex: 1, width: '100%'}}>
      <Tooltip
        title={
          <Stack>
            {items.map((item) => (
              <Typography variant="body2" key={item}>
                {item}
              </Typography>
            ))}
          </Stack>
        }>
        <div>
          <Stack>
            <Typography variant="body2" noWrap>
              {visibleItems.join(', ')}
            </Typography>
            {showAdditionalItems && (
              <Typography
                sx={{
                  color: Theme.colorPalette.darkest,
                  fontSize: Theme.typography.size.s1,
                  fontWeight: Theme.typography.weight.bold,
                }}
                data-testid="show-more"
                noWrap>
                {`+ ${hiddenItemCount} more`}
              </Typography>
            )}
          </Stack>
        </div>
      </Tooltip>
    </Box>
  );
};
