// @flow
import {Validation} from '@wellstone-solutions/common';

const {isRequired, isOptional, isEmail, isUrl} = Validation;

export const schema = {
  title: {
    validate: isRequired,
    error: 'Title is required',
  },
  subtitle: isOptional,
  description: isOptional,
  email: {
    validate: (value: string): boolean =>
      !value || value.length === 0 || isEmail(value),
    error: 'Invalid email address',
  },
  phoneNumber: isOptional,
  website: {
    validate: (value: mixed): boolean =>
      !value || value.length === 0 || isUrl(value),
    error: 'Invalid website URL',
  },
  dateTimeInformation: isOptional,
  location: isOptional,
};
