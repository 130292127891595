// @flow
import {
  action,
  observable,
  runInAction,
  IObservableArray,
  makeObservable,
} from 'mobx';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {
  UIResourceType,
  UICollectionType,
} from '@wellstone-solutions/common/models/rest';
import {Collection, Resource} from '@wellstone-solutions/common/models/rest';
import {RootStore} from 'RootStore';

export class ResourceV2Store {
  collections: IObservableArray<UICollectionType> = observable.array();
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      collections: observable,
      getCollections: action,
    });

    this.rootStore = rootStore;
  }

  async init(): Promise<mixed> {
    return this.getCollections();
  }

  async getCollections(
    forceFetch: boolean = false,
  ): Promise<ApiResponseType<IObservableArray<UICollectionType> | []>> {
    const response = await Collection.getAll();

    if (!response.isSuccess) {
      return {...response, data: []};
    }

    const resourceCollections = response.data.filter(
      (collection) => collection.collection_type.name === 'Resources',
    );

    const uiCollections = resourceCollections.map(Collection.toUI);

    runInAction(() => {
      this.collections.replace(uiCollections);
    });

    return {...response, data: this.collections};
  }

  async getResource(
    id: string,
  ): Promise<ApiResponseType<UIResourceType | null>> {
    const response = await Resource.get(id);

    if (!response.isSuccess) {
      return {...response, data: null};
    }

    let uiResponse = Resource.toUI(response.data);
    return {...response, data: uiResponse};
  }

  async createResource(
    resource: UIResourceType,
  ): Promise<ApiResponseType<UIResourceType | null>> {
    const payload = Resource.toApiInput(resource);
    const response = await Resource.create(payload);

    if (!response.isSuccess) {
      return {...response, data: null};
    }

    const uiResponse = Resource.toUI(response.data);
    return {...response, data: uiResponse};
  }

  async updateResource(
    resource: UIResourceType,
  ): Promise<ApiResponseType<UIResourceType | null>> {
    const {id} = resource;
    const payload = Resource.toApiInput(resource);
    const response = await Resource.update(id, payload);

    if (!response.isSuccess) {
      return {...response, data: null};
    }

    const uiResponse = Resource.toUI(response.data);
    return {...response, data: uiResponse};
  }

  async deleteResource(id: string): Promise<boolean> {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this resource?',
    );

    if (!isConfirmed) {
      return false;
    }

    const response = await Resource.remove(id);

    if (response.isSuccess) {
      this.rootStore.alertStore.addSuccess('Resource deleted successfully');
    } else {
      this.rootStore.alertStore.addError('Failed to delete resource');
    }

    return response.isSuccess;
  }
}
