// @flow
import React from 'react';
import type {Node} from 'react';
import {
  Box,
  Dialog,
  IconButton,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {useStores} from 'hooks/useStores';
import {ResourceForm} from '../ResourceForm';
import {Resource} from '@wellstone-solutions/common/models/rest';

type PropsType = {
  open?: boolean,
  onClose: () => void,
  onSuccess: (resource: UIResourceType) => void,
};

export const NewResource = ({
  open = false,
  onClose,
  onSuccess,
}: PropsType): Node => {
  const {resourceV2Store} = useStores();

  const onSubmit = async (values) => {
    const response = await resourceV2Store.createResource(values);

    if (response.isSuccess && response.data) {
      onSuccess(response.data);
    }

    return response;
  };

  return (
    <Dialog open={open} fullScreen={true} onClose={onClose}>
      <Box p={4}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}>
          <Stack direction="row" alignItems="center">
            <IconButton icon={IconNames.ArrowLeftCircle} onClick={onClose} />
            <Typography variant="h5">Create Resource</Typography>
          </Stack>
        </Stack>
        <Box>
          <ResourceForm
            initialValues={Resource.Factory()}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
