// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {Api} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  Paper,
  Icon,
  IconNames,
  Stack,
  Typography,
  GridActionsCellItem,
} from '@wellstone-solutions/web';
import {Resource} from '@wellstone-solutions/common/models/rest';
import {useStores} from 'hooks/useStores';
import {PagedDataGrid, ListCell, useOptimisticRows} from 'modules/datagrid';
import {formatDate} from 'utils/date';
import {DATE} from 'constants/dateFormats';
import {EditResource} from '../EditResource';
import {NewResource} from '../NewResource';
import {ResourceSearch} from '../ResourceSearch';
import {LocationSearch} from '../LocationSearch';
import {useActiveFilters} from '../../hooks';

export const List = (): Node => {
  const {resourceV2Store} = useStores();
  const [query, setQuery] = useState('');
  const [location, setLocation] = useState(null);
  const [resourceToEdit, setResourceToEdit] = useState(null);
  const [newResourceOpen, setNewResourceOpen] = useState(false);
  const activeFilters = useActiveFilters({query, location});

  const {
    optimisticRows,
    addOptimisticRow,
    setOptimisticRows,
    clearOptimisticRows,
  } = useOptimisticRows();

  const handleDelete = async (id): Promise<void> => {
    const rollbackRows = [...optimisticRows];

    addOptimisticRow({
      id,
      newData: false,
    });

    const isSuccess = await resourceV2Store.deleteResource(id);
    if (isSuccess && resourceToEdit) {
      // Close edit modal if open
      setResourceToEdit(null);
    } else if (!isSuccess) {
      // Rollback optimistic row if delete fails
      setOptimisticRows(rollbackRows);
    }
  };

  const getRowActions = (resource): Array<Node> => {
    return [
      <GridActionsCellItem
        label="Edit"
        key="edit"
        icon={<Icon name={IconNames.PageEdit} />}
        showInMenu={true}
        onClick={() => {
          setResourceToEdit(resource);
        }}
      />,
      <GridActionsCellItem
        label="Delete"
        key="delete"
        icon={<Icon name={IconNames.Trash} />}
        showInMenu={true}
        onClick={() => {
          handleDelete(resource.id);
        }}
      />,
    ];
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
      renderCell: ({row}) => (
        <Box>
          <Typography>{row.title}</Typography>
          {row.subtitle && (
            <Typography variant="body2">{row.subtitle}</Typography>
          )}
        </Box>
      ),
    },
    {
      field: 'formattedAddress',
      headerName: 'Full Address',
      flex: 2,
      valueGetter: ({row}) => row.location?.formattedAddress,
    },
    {
      field: 'collections',
      headerName: 'Categories',
      flex: 1,
      renderCell: ({row}) =>
        row.collections.length > 0 && (
          <ListCell items={row.collections.map((c) => c.name)} />
        ),
    },
    {
      field: 'modified',
      headerName: 'Last Updated',
      flex: 1,
      valueFormatter: ({value}) => formatDate(value, DATE),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 1,
      maxWidth: 90,
      getActions: ({row: resource}) => getRowActions(resource),
    },
  ];

  return (
    <>
      <Paper>
        <Stack gap={2} p={2} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Typography variant="h5">Resources</Typography>
            <Button
              variant="contained"
              fullWidth={false}
              startIcon={<Icon name={IconNames.Plus} color="inherit" />}
              onClick={() => setNewResourceOpen(true)}>
              New Resource
            </Button>
          </Stack>
          <Stack direction="row" gap={2}>
            <ResourceSearch onSearch={setQuery} />
            <LocationSearch onSelect={setLocation} />
          </Stack>
        </Stack>

        <PagedDataGrid
          // $FlowFixMe - Error with MUI Column type
          columns={columns}
          params={activeFilters}
          dataTransformer={({data, total_count}) => ({
            rows: data.map(Resource.toUI),
            total: total_count,
          })}
          initialSortField="title"
          initialSortOrder="asc"
          url={Resource.routes.search()}
          apiVersion={Api.API_VERSIONS.REST}
          elevation={0}
          onRowClick={({row}) => setResourceToEdit(row)}
          sx={{
            '& .MuiDataGrid-cell': {
              cursor: 'pointer',
            },
          }}
          optimisticRows={optimisticRows}
          clearOptimisticRows={clearOptimisticRows}
        />
      </Paper>

      <EditResource
        resource={resourceToEdit}
        open={Boolean(resourceToEdit)}
        onClose={() => setResourceToEdit(null)}
        onSuccess={(resource) => {
          addOptimisticRow({
            id: resource.id,
            newData: resource,
          });
        }}
        onDelete={handleDelete}
      />

      <NewResource
        open={newResourceOpen}
        onClose={() => setNewResourceOpen(false)}
        onSuccess={(resource) => {
          addOptimisticRow({
            id: resource.id,
            newData: resource,
          });
          setNewResourceOpen(false);
        }}
      />
    </>
  );
};
